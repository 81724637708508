import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { User } from 'src/app/models/user/user';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Role } from 'src/app/models/user/role.enum';
import { AuthService } from 'src/app/auth/auth.service';
import { GmsService } from 'src/app/services/gms.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { GmsNonClassified } from 'src/app/models/gms/gms-non-classified';
import { GmsAccessService } from 'src/app/services/gms-access.service';
import { GmsAccessRequestTableItem } from 'src/app/models/gms/access/gms-access-request-table-item';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-user-edit',
    templateUrl: './user-edit.component.html',
    styleUrls: ['./user-edit.component.css'],
})
export class UserEditComponent implements OnInit {
    iAmAdmin = false;
    iAmGdsPartner = false;
    iAmCustomerManager = false;

    user: User;
    userIsCustomerManager = false;
    userEditForm = new UntypedFormGroup({
        role: new UntypedFormControl(''),
        gmsIds: new UntypedFormControl([]),
        accountManager: new UntypedFormControl(''),
    });
    roles: Role[];

    gmsListNonClassified: GmsNonClassified[];

    loadingUserData = true;
    loadingGms = true;
    loadingAccountManagers = true;
    loadingGmsRequests = true;
    loadingSubUsers = true;

    possibleAccountManagers: string[] = [];

    gmsRequests: GmsAccessRequestTableItem[] = [];
    gmsRequestDataSource: MatTableDataSource<GmsAccessRequestTableItem>;
    displayedGmsRequestColumns = ['gms', 'creationDate'];

    subUsers: User[] = [];
    subUserDataSource: MatTableDataSource<User>;
    displayedSubUserColumns = ['username', 'firstname', 'lastname', 'company', 'position'];

    @ViewChildren('gmsRequestTbSort') gmsRequestTbSort: QueryList<MatSort>;

    @ViewChildren('subUserTbSort') subUserTbSort: QueryList<MatSort>;
    @ViewChildren('subUserTbPaginator') subUserTbPaginator: QueryList<MatPaginator>;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private userService: UserService,
                private authService: AuthService,
                private gmsService: GmsService,
                private gmsAccessService: GmsAccessService) {
    }

    ngOnInit(): void {
        this.iAmAdmin = this.authService.getRole() === Role.ADMIN;
        this.iAmGdsPartner = this.authService.getRole() === Role.GDS_PARTNER;
        this.iAmCustomerManager = this.authService.getRole() === Role.CUSTOMER_MANAGER;

        this.reloadUser();
    }

    reloadUser() {
        this.loadingUserData = true;
        this.userService.get(this.route.snapshot.paramMap.get('id')).subscribe(user => {
            this.user = user;

            this.userIsCustomerManager = this.user.role === Role.CUSTOMER_MANAGER;

            this.userEditForm.patchValue({
                role: this.user.role,
                gmsIds: this.user.gmsIds,
                accountManager: this.user.accountManagerUsername,
            });

            if ((!this.iAmAdmin && this.editingMyself()) || !(this.iAmAdmin || this.iAmGdsPartner)) {
                this.roles = [this.user.role];
                this.userEditForm.get("role").disable();
            } else {
                switch (this.authService.getRole()) {
                    case Role.ADMIN:
                        this.roles = [Role.LITE, Role.BASIS, Role.PLUS, Role.ADMIN, Role.GDS_PARTNER, Role.CUSTOMER_MANAGER, Role.DELIVERY, Role.AWAITING_2FA];
                        break;
                    case Role.GDS_PARTNER:
                        this.roles = [Role.BASIS, Role.CUSTOMER_MANAGER];
                        break;
                }
            }

            if (this.iAmAdmin) {
                this.reloadPossibleAccountMangers()
            } else {
                this.possibleAccountManagers = [this.user.accountManagerUsername];
                this.userEditForm.get("accountManager").disable();

                this.loadingAccountManagers = false;
            }

            if (this.iAmAdmin || this.iAmGdsPartner) {
                this.reloadGmsRequests();
            } else {
                this.loadingGmsRequests = false;
            }

            if (this.userIsCustomerManager) {
                this.reloadSubUsers();
            } else {
                this.loadingSubUsers = false;
            }

            this.loadingUserData = false;
        });

        this.gmsService.getAllNonClassified().subscribe(gmsList => {
            this.gmsListNonClassified = gmsList;

            this.loadingGms = false;
        });
    }

    reloadPossibleAccountMangers() {
        firstValueFrom(this.userService.getAllAdminsAndPartnersUsernames())
            .then(usernames => {
                this.possibleAccountManagers = usernames.filter(username => username != this.user.username);
            })
            .catch(error => {
                this.possibleAccountManagers = [this.user?.accountManagerUsername];
            })
            .finally(() => {
                this.loadingAccountManagers = false;
            });
    }

    reloadGmsRequests() {
        this.loadingGmsRequests = true;

        firstValueFrom(this.gmsAccessService.getAllRequestsByUser(this.user.username))
            .then(gmsRequestList => {
                this.gmsRequests = gmsRequestList;

                this.gmsRequestDataSource = new MatTableDataSource<GmsAccessRequestTableItem>(this.gmsRequests.sort((a, b) => b.creationDate.localeCompare(a.creationDate)));

                this.gmsRequestTbSort.changes.subscribe((comps: QueryList<MatSort>) => {
                    this.gmsRequestDataSource.sort = comps.first;
                });

                this.loadingGmsRequests = false;
            })
            .catch(error => {
                this.gmsRequests = [];
                this.loadingGmsRequests = false;
            });
    }

    reloadSubUsers() {
        this.loadingSubUsers = true;

        firstValueFrom(this.userService.getSubUsers(this.user.username))
            .then(subUsers => {
                this.subUsers = subUsers;

                this.subUserDataSource = new MatTableDataSource<User>(this.subUsers);

                this.subUserTbSort.changes.subscribe((comps: QueryList<MatSort>) => {
                    this.subUserDataSource.sort = comps.first;
                });
                this.subUserTbPaginator.changes.subscribe((comps: QueryList<MatPaginator>) => {
                    this.subUserDataSource.paginator = comps.first;
                });

                this.loadingSubUsers = false;
            })
            .catch(error => {
                this.subUsers = [];
                this.loadingSubUsers = false;
            });
    }

    applyChanges() {
        this.userService.updateAdministrativeData({
            ...this.user,
            role: this.userEditForm.get('role').value,
            gmsIds: this.userEditForm.get('gmsIds').value,
            accountManagerUsername: this.userEditForm.get('accountManager').value,
        }).subscribe(user => {
            if (this.authService.getRole() !== Role.CUSTOMER_MANAGER
                || this.authService.getGmsIdsForUser().some(item => user.gmsIds.includes(item))) {
                this.reloadUser();
                this.userEditForm.markAsPristine();
            } else {
                this.router.navigate(['../admin']);
            }
        });
    }

    onClickGmsRequest(gmsRequestToken: string) {
        window.open('#/gms-access/handle/' + gmsRequestToken);
    }

    editingMyself(): boolean {
        return this.authService.getUsername() === this.user.username;
    }

    iAmTheAccountManager() {
        return this.authService.getUsername() === this.user.accountManagerUsername;
    }
}
